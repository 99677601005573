import { defineStore } from 'pinia';
import type { ClientWithDetailsE } from '~/types/clients';
import type { BasicUser } from '~/types/users';

export const useClientStore = defineStore('ClientStore', {
  state: () => ({
    clients: new Map<string, ClientWithDetailsE>(),
    currentClient: null as ClientWithDetailsE | null,
    listExpire: null as Date | null,
  }),
  getters: {
    getClient: (state) => (clientSlug: string) => {
      state.currentClient = null;
      if (!state.clients.get(clientSlug)) return null;
      const client = state.clients.get(clientSlug)!;
      if (client.expire < new Date()) {
        state.clients.delete(clientSlug);
        return null;
      }
      return client;
    },
    getClients: (state) => () => {
      if (state.listExpire && state.listExpire < new Date()) {
        return Object.values(state.clients);
      } else {
        return null;
      }
    },
    currentClientTeam: (state): BasicUser[] => {
      if (!state.currentClient || !state.currentClient.collaborators) return [];
      return state.currentClient.collaborators
        .filter(
          (c) =>
            !!c.user.agencyId &&
            c.user.agencyId === state.currentClient!.agencyId
        )
        .map((c) => c.user);
    },
  },
  actions: {
    setClient(clientSlug: string, client: ClientWithDetailsE) {
      this.clients.set(clientSlug, client);
    },
    setCurrentClient(clientSlug: string) {
      this.currentClient = this.clients.get(clientSlug) || null;
    },
    reset() {
      this.clients = new Map();
      this.currentClient = null;
      this.listExpire = null;
    },
  },
});
